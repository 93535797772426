import store from '@/state/store.js';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Sender Names reports',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      search: '',
      tableData: [],
      tableLoading: false
    };
  },
  computed: {
    tableDataComputed() {
      return this.tableData;
    },
    searchingTableData() {
      if (!this.search) {
        this.total = this.tableData.length;
        return this.tableData;
      }
      this.page = 1;
      return this.tableData.filter(data => data.sender_id && data.sender_id.toLowerCase().includes(this.search.toLowerCase()) || data.username && data.username.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.fetchData(val);
    },
    fetchData(page = 1) {
      this.tableLoading = true;
      store.dispatch('admin/reporting/getSenderNamesReport', {
        page
      }).then(data => {
        this.tableData = data.data;
        this.total = data.total;
      }).finally(() => this.tableLoading = false);
    }
  },
  mounted() {
    this.fetchData();
  }
};